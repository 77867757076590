<template id="player_edit">
  <player-data />
</template>

<script>
import PlayerData from "@/components/PlayerData.vue";
export default {
  components: {
    PlayerData,
  },
};
</script>
